import React, { useRef } from "react";
// import { Link } from "react-router-dom";
import "./Restaurants.css";
import page from "../../assets/images/number.png";
import CustomBtn from "../../components/CustomBtn/CustomBtn";
// import profile from "../../assets/images/face.png";
import addicon from "../../assets/icons/addicon.png";
// import CustomPopup from "../../components/CustomPopup/CustomPopup";
import customicon from "../../assets/icons/addicon.png";
// import menu from "../../assets/icons/darkmenu.png";
// import user from "../../assets/icons/user.png";
// import graph from "../../assets/icons/graph.png";

import AddRestaurant from "../../components/AddRestaurant/AddRestaurant";
import ARMenu from "../../components/ARMenu/ARMenu";
import StatusBar from "../../components/StatusBar/StatusBar";

const Restaurants = () => {
  const addRestaurantDialogRef = useRef(null);
  const viewARMenuDialogRef = useRef(null);

  const openAddRestaurantDialog = () => {
    addRestaurantDialogRef.current.showModal();
  };

  const closeAddRestaurantDialog = () => {
    addRestaurantDialogRef.current.close();
  };

  const openViewARMenuDialog = () => {
    viewARMenuDialogRef.current.showModal();
  };

  const closeViewARMenuDialog = () => {
    viewARMenuDialogRef.current.close();
  };

  const items = [1, 2, 3, 4, 5];
  return (
    <div className="Home">
      <StatusBar />
      <div className="main-container ">
        <div className="restro-header">
          <div className="restro-heading">Registered Restaurants</div>
          <div>
            <CustomBtn
              name="Add New Restaurant"
              customicon={addicon}
              openPopup={openAddRestaurantDialog}
            />
            <AddRestaurant
              closePopup={closeAddRestaurantDialog}
              dialogRef={addRestaurantDialogRef}
            />
          </div>
        </div>
        <div className="main-container-head flex-r ">
          <p className="main-container-heading flex-1 text-c">Name</p>
          <p className="main-container-heading flex-1 text-c">Mobile</p>
          <p className="main-container-heading flex-1 text-c">Email</p>
          <p className="main-container-heading flex-1 text-c">Location</p>
          <p className="main-container-heading flex-1 text-c"></p>
        </div>
        {items.map((item) => (
          <>
            <div className="details-container">
              <div className="user-details flex-1">
                <div>
                  {/* <img src={profile} width="40" /> */}
                  Pizza House
                </div>
              </div>
              <div className="user-details flex-1">+91 8653255256</div>
              <div className="user-details flex-1">test@gamil.com</div>
              <div className="user-details flex-1">Pune</div>
              <div className="user-details flex-1">
                <CustomBtn
                  name="View AR Menu"
                  customicon={customicon}
                  openPopup={openViewARMenuDialog}
                />
                <ARMenu
                  closePopup={closeViewARMenuDialog}
                  dialogRef={viewARMenuDialogRef}
                />
              </div>
            </div>
            <div className="line-vertical"></div>
          </>
        ))}
        <div className="pagenation-num-2">
          <img src={page} alt="my_user" />
        </div>
      </div>
    </div>
  );
};

export default Restaurants;
