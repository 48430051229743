import React from "react";
import "./AddARMenu.css";
// import arfood from "../../assets/icons/arfood.png";
// import mob from "../../assets/icons/whitemobile.png";
// import page from "../../assets/images/number.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
// import addicon from "../../assets/icons/addicon.png";
import rupee from "../../assets/icons/rupee.png";
import download from "../../assets/icons/download_up.png";
import CustomBtn from "../CustomBtn/CustomBtn";

const AddARMenu = ({ closePopup, dialogRef }) => {
  const handleSubmit = () => {
    closePopup();
  };
  return (
    <div className="popup-add-2">
      <dialog ref={dialogRef} className="center-dialog-2">
        <div className="ARMenu-2">
          <div className="ARMenu-head">
            <div className="flex-r align-center">
              <div className="ar-header">
                <h3>Add AR Menu </h3>
              </div>
            </div>
          </div>
          <div className="half-line-gradient"></div>

          <div className="input-container">
            {/* First Div */}
            <div className="first-div flex-r">
              <div className="flex-c">
                <label htmlFor="name" className="common-label">
                  Item Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  className="input-design item-name"
                />
              </div>
              <div className="flex-c">
                <label htmlFor="price" className="common-label">
                  Price
                </label>
                <div className="rupee-container">
                  <img src={rupee} alt="my user" />
                  <input
                    type="text"
                    id="price"
                    className="input-design price"
                  />
                </div>
              </div>
            </div>

            {/* Second Div */}
            <div className="second-div flex-r">
              <div className="flex-c">
                <label htmlFor="address" className="common-label">
                  Upload Display Image
                </label>
                <div className="download-container">
                  <input
                    type="text"
                    id="address"
                    // placeholder="Address"
                    className="input-design upload"
                  />
                  <img src={download} alt="my user" />
                </div>
              </div>
              <div className="flex-c">
                <label htmlFor="pincode" className="common-label">
                  Upload AR Model File
                </label>
                <div className="download-container">
                  <input className="input-design ar-file" />
                  <img src={download} alt="my user" />
                </div>
              </div>
            </div>
          </div>
          <div class="container-grad">
            <div class="gradient-1"></div>
            <p className="common-label-or">or</p>
            <div class="gradient-2"></div>
          </div>
          <div className="upload-files">
            <div>Upload File</div>
            <div>(Only xlxs, csv format allowed)</div>
          </div>

          <div className="finish-btn">
            <CustomBtn name="Finish" openPopup={handleSubmit} />
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default AddARMenu;
