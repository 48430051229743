import React, { useRef } from "react";
import "./ARMenu.css";

import arfood from "../../assets/icons/arfood.png";
import mob from "../../assets/icons/whitemobile.png";
import page from "../../assets/images/number.png";
import export_logo from "../../assets/icons/export.png";
import addicon from "../../assets/icons/addicon.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
// import CustomBtn from "../CustomBtn/CustomBtn";
import AddARMenu from "../AddARMenu/AddARMenu";

const ARMenu = ({ closePopup, dialogRef }) => {
  const addARMenuDialogRef = useRef(null);

  // const openAddARMenuDialog = () => {
  //   addARMenuDialogRef.current.showModal();
  // };

  const closeAddARMenuDialog = () => {
    addARMenuDialogRef.current.close();
  };

  const items = [1, 2, 3, 4, 5];
  return (
    <div className="popup">
      <dialog ref={dialogRef} className="center-dialog">
        <div className="ARMenu">
          <div className="ARMenu-head">
            <div className="flex-r align-center">
              <div>
                <img src={arfood} alt="food" />
              </div>
              <div className="ar-header">
                <h3>Pizza House AR Menu </h3>
                <div className="ar-ratings">
                  <FontAwesomeIcon icon={faStar} color="#FFAD14" /> 4.5(23)
                </div>
              </div>
            </div>

            <div className="flex-r align-center">
              <div className="align-center flex-c">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  width={10}
                  color="#FFF"
                />
                <div className="location-name" style={{ fontSize: "14px" }}>
                  Pune
                </div>
              </div>
              <div onClick={closePopup} className="cursor_pointer">
                <FontAwesomeIcon icon={faTimes} color="#FFAD14" width={13} />
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "right",
              fontFamily: "Source Sans 3",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0em",
              color: "#FFF",
              marginTop: "-15px",
            }}
          >
            Export
            <img
              src={export_logo}
              alt="icon"
              width={15}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div className="gradient-line"></div>
            <button
              // onClick={() => handleClick()}
              className="custom-btn"
              style={{ height: "30px", padding: "5px" }}
            >
              <p style={{ fontSize: "10px" }}>Add new item</p>
              <img src={addicon} alt="add icon" />
            </button>
            <AddARMenu
              closePopup={closeAddARMenuDialog}
              dialogRef={addARMenuDialogRef}
            />
          </div>
          <div className="counter-header">
            <p>Item</p>
            <p>View in AR</p>
            <p>Price</p>
            <p>Rating</p>
          </div>

          {items.map((item) => (
            <div key={item} className="child">
              <div className="item-list">
                <div>
                  <pre>{"  "}Veg Hakka noodles </pre>
                </div>
                <div>
                  <img src={mob} alt="mob" />
                </div>
                <div>
                  <pre>{"           "}₹ 316</pre>
                </div>
                <div className="ar-ratings">
                  <FontAwesomeIcon icon={faStar} color="#FFAD14" /> 4.5(23)
                </div>
              </div>
              <div className="grey-gradient"></div>
            </div>
          ))}
          <div className="pagenation-num">
            <img src={page} alt="mob" />
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default ARMenu;
