import React from "react";
import "./ViewOrder.css";

import arfood from "../../assets/icons/arfood.png";
import mob from "../../assets/icons/whitemobile.png";
import page from "../../assets/images/number.png";
import food from "../../assets/icons/food.png";
import rating from "../../assets/icons/rating.png";
import export_logo from "../../assets/icons/export.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const ViewOrder = ({ closePopup, dialogRef }) => {
  const items = [1, 2, 3, 4, 5];
  return (
    <div className="popup">
      <dialog ref={dialogRef} className="center-dialog">
        <div className="ARMenu">
          <div className="ARMenu-head">
            <div className="flex-r align-center">
              <div>
                <img src={arfood} alt="my_user" />
              </div>
              <div className="ar-header">
                <h3>Pizza House AR Menu </h3>
                <div className="ar-ratings">
                  <FontAwesomeIcon icon={faStar} color="#FFAD14" /> 4.5(23)
                </div>
              </div>
            </div>

            <div className="flex-r align-center">
              <div className="align-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" color="#FFF" />
                <div className="location-name">Pune</div>
              </div>
              <div onClick={closePopup} className="cursor_pointer">
                <FontAwesomeIcon icon={faTimes} color="#FFAD14" size="2x" />
              </div>
            </div>
          </div>
          <div className="grad-order-div">
            <div className="gradient-line"></div>
            <div>
              Export <img src={export_logo} alt="my_user" />
            </div>
          </div>
          <div className="counter-header">
            <p>Restaurant </p>
            <p>Order Item</p>
            <p>Quantity</p>
            <p>Price</p>
            <p>Date</p>
          </div>

          {items.map((item) => (
            <div key={item} className="child">
              <div className="item-list orders-list">
                <div className="flex-r ">
                  <img src={food} width="33" alt="my_user" />
                  <div>
                    <p>Pizza House</p>
                    <img src={rating} width="99" alt="my_user" />
                  </div>
                </div>
                <div className="flex-c">
                  <div className="flex-r">
                    <p>Veg Hakka Nood</p>
                    <img src={mob} width="8" alt="my_user" className="mob" />
                  </div>
                  <div className="flex-r">
                    <p>Veg Hakka Nood</p>
                    <img src={mob} width="8" alt="my_user" className="mob" />
                  </div>
                  <div className="flex-r">
                    <p>Veg Hakka Nood</p>
                    <img src={mob} width="8" alt="my_user" className="mob" />
                  </div>
                </div>
                <div>
                  <p>1</p>
                  <p>1</p>
                  <p>3</p>
                </div>
                <div>
                  <pre>₹ 316</pre>
                  <pre>₹ 316</pre>
                  <pre>₹ 316</pre>
                </div>
                {/* <div className="ar-rating-2"> */}
                {/* <FontAwesomeIcon icon={faStar} color="#FFAD14" /> */}
                23-12-2024
                {/* </div> */}
              </div>
              <div className="grey-gradient"></div>
            </div>
          ))}
          <div className="pagenation-num">
            <img src={page} alt="my_user" />
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default ViewOrder;
